import slice from "./slice";
// import * as actions from "./actions";
import * as selectors from "./selectors";
// import * as api from "./api";
import * as consts from "./consts";

export default {
  slice,
  selectors,
  consts,
  // actions,
  // api,
};
